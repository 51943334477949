import {getApp} from "@/appsManager/helpers"
import React, {useEffect} from "react";
import ReactDOM from 'react-dom/client';
import PageManager from "@/commons/loadingPage/PageManager";
import {InfoBox, InfoBoxProvider} from "@/commons/infoBox/InfoBox";
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
document.title = "EBLCraft";
let CurrentApp = getApp();
root.render(
    <div>
        <meta name="description" content="Sito web di EBLCraft, il server di ErenBlaze!"/>
        <meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate"/>
        <meta httpEquiv="Pragma" content="no-cache"/>
        <meta httpEquiv="Expires" content="0"/>
        <InfoBoxProvider>
            <PageManager/>
            <InfoBox/>
            <CurrentApp/>
        </InfoBoxProvider>
    </div>);