import Redirects from "@/commons/utils/Redirects";
import {useEffect, useState} from "react";
import ApiInterface from "@/commons/api/ApiInterface";

class ServerPreview {
    constructor(title, description) {
        this.title = title;
        this.description = description;
    }

    renderer() {
        const WrappedComponent = () => {
            const [previewHtml, setPreviewHtml] = useState(this.description ?? "");

            const loadImages = async (content) => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(content, "text/html");
                const imgTags = doc.querySelectorAll("img");

                let hasChanges = false;

                for (const img of imgTags) {
                    const src = img.getAttribute("src");

                    if (src && !src.startsWith("http") && !src.startsWith("data:image")) {

                        try {
                            const imageRes = await ApiInterface.getFileUrl(src);
                            console.log("Found ", imageRes)

                            if (imageRes !== null) {
                                img.setAttribute("src", imageRes);
                                hasChanges = true;
                            }
                        } catch (error) {
                            console.error("Errore durante il caricamento dell'immagine: ", error);
                        }
                    }
                }

                if (hasChanges) {
                    return doc.documentElement.innerHTML;
                }
                return null;
            };

            const loadPreview = async () => {
                let newHtml = await loadImages(previewHtml);
                if(newHtml === null) return
                setPreviewHtml(newHtml);
            }

            useEffect(() => {
                loadPreview()
            }, [previewHtml])

            return (
                <div className={"serverPage__mod"} onClick={(e) => {
                    Redirects.send("/server/"+this.title, e)
                }}>
                    <div className={"serverPage__mod_text"}>
                        <span className={"serverPage__mod_title"}>{this.title}</span>
                        <span className={"serverPage__mod_description"}
                              dangerouslySetInnerHTML={{__html: previewHtml}}></span>
                    </div>
                </div>
            )
        }
        return <WrappedComponent/>;
    }
}

export default ServerPreview;