import React, { useEffect, useState } from "react";

/**
 * FormBuilder sequenziale a più livelli:
 * - Se un blocco ha un input (text, number, selection, combo, coords), rimane “closed” finché non è “valido”.
 * - Se un blocco è “submit”, “option”, “message” (senza input), lo consideriamo “open = true” by default,
 *   così viene renderizzato subito e, se è “submit”, chiama `onSubmitDetected`.
 */
export default function FormBuilder({ formElement, onChange, onSubmitDetected }) {
    const [values, setValues] = useState({});

    useEffect(() => {
        if (onChange) {
            onChange(values);
        }
    }, [values, onChange]);

    function handleBlockChange(blockData) {
        setValues((prev) => ({ ...prev, ...blockData }));
    }

    if (!formElement) return null;

    if (Array.isArray(formElement)) {
        return (
            <>
                {formElement.map((elem, idx) => (
                    <Block
                        key={idx}
                        element={elem}
                        onBlockChange={handleBlockChange}
                        onSubmitDetected={onSubmitDetected}
                    />
                ))}
            </>
        );
    }

    return (
        <Block
            element={formElement}
            onBlockChange={handleBlockChange}
            onSubmitDetected={onSubmitDetected}
        />
    );
}

/**
 * “Block” => un campo “progressivo”.
 * - Se il block ha un input (text/number/coords/selection/combo),
 *   lo consideriamo “open=false” di default e si sblocca quando il valore è “valido”.
 * - Se invece “submit”, “option”, “message” => non c’è un input “da validare”,
 *   quindi settiamo “open=true” in partenza.
 */
function Block({ element, onBlockChange, onSubmitDetected }) {
    const {
        type,
        name,
        label,
        defaultValue = "",
        formScope,
        childrens = []
    } = element || {};

    // Se è un “submit”, “option” o “message” => open di default = true
    const isNoInputType = ["submit", "option", "message", "default"].includes(
        (type || "").toLowerCase()
    );

    const [open, setOpen] = useState(isNoInputType ? true : false);

    // Stato “value”
    const [localValue, setLocalValue] = useState(defaultValue);
    const [coords, setCoords] = useState({ x: "", y: "", z: "" });
    const [comboChecked, setComboChecked] = useState({});

    // Se type="submit", chiamiamo onSubmitDetected in un useEffect
    useEffect(() => {
        if ((type || "").toLowerCase() === "submit" && onSubmitDetected) {
            onSubmitDetected(name);
        }
    }, [type, name, onSubmitDetected]);

    // Avvisa il form builder
    function notify(fieldName, val) {
        onBlockChange({ [fieldName]: val });
    }

    function isValid(value, blockType) {
        switch (blockType) {
            case "text":
            case "selection":
                return value.trim() !== "";
            case "number":
                return value !== "";
            case "coordinates":
                return value.x !== "" && value.y !== "" && value.z !== "";
            case "combo":
                return Object.values(value).some(checked => checked === true);
            default:
                return true; // “submit”, “option”, “message” => true
        }
    }

    let mainContent = null;

    switch ((type || "").toLowerCase()) {
        case "text":
            mainContent = (
                <form-box>
                    <label>{label}</label>
                    <br />
                    <form-content>
                        <input
                            type="text"
                            name={name}
                            defaultValue={defaultValue}
                            onChange={(e) => {
                                const val = e.target.value;
                                setLocalValue(val);
                                notify(label, val);
                                setOpen(isValid(val, "text"));
                            }}
                        />
                    </form-content>
                    <br />
                </form-box>
            );
            break;

        case "number":
            mainContent = (
                <form-box>
                    <label>{label}</label>
                    <br />
                    <form-content>
                        <input
                            type="number"
                            name={name}
                            defaultValue={defaultValue}
                            onChange={(e) => {
                                const val = e.target.value;
                                setLocalValue(val);
                                notify(label, val);
                                setOpen(isValid(val, "number"));
                            }}
                        />
                    </form-content>
                    <br />
                </form-box>
            );
            break;

        case "coordinates":
        function handleCoordChange(coordName, val) {
            setCoords(prev => {
                const updated = { ...prev, [coordName]: val };
                const coordsStr = `${updated.x} ${updated.y} ${updated.z}`;
                notify(label, coordsStr);
                setOpen(isValid(updated, "coordinates"));
                return updated;
            });
        }
            mainContent = (
                <form-box>
                    <label>{label}</label>
                    <br />
                    <form-content name="coords">
                        <span className="coordsLabel">x: </span>
                        <input
                            type="number"
                            onChange={(e) => handleCoordChange("x", e.target.value)}
                        />
                        <br />
                        <span className="coordsLabel">y: </span>
                        <input
                            type="number"
                            onChange={(e) => handleCoordChange("y", e.target.value)}
                        />
                        <br />
                        <span className="coordsLabel">z: </span>
                        <input
                            type="number"
                            onChange={(e) => handleCoordChange("z", e.target.value)}
                        />
                        <br />
                    </form-content>
                    <br />
                </form-box>
            );
            break;

        case "selection":
            mainContent = (
                <form-box>
                    <label>{label}</label>
                    <br />
                    <form-content>
                        <select
                            name={name}
                            onChange={(e) => {
                                const val = e.target.value;
                                setLocalValue(val);
                                notify(label, val);
                                setOpen(isValid(val, "selection"));
                            }}
                        >
                            <option value="">(Seleziona...)</option>
                            {childrens.map((opt, idx) => {
                                if ((opt.type || "").toLowerCase() === "option") {
                                    return (
                                        <option
                                            key={idx}
                                            value={opt.name}
                                        >
                                            {opt.label}
                                        </option>
                                    );
                                }
                                return null;
                            })}
                        </select>
                    </form-content>
                    <br />
                </form-box>
            );
            break;

        case "combo":
            mainContent = (
                <form-box>
                    <label id={name}>{label}</label>
                    <br />
                    <form-content name="combo">
                        {childrens.map((opt, idx) => {
                            if ((opt.type || "").toLowerCase() === "option") {
                                return (
                                    <comboBox key={idx}>
                                        <input
                                            type="checkbox"
                                            onChange={(e) => {
                                                const updated = { ...comboChecked, [opt.name]: e.target.checked };
                                                let str = "";
                                                Object.entries(updated).forEach(([k, v]) => {
                                                    str += `${k}=${v ? "Yes" : "No"}; `;
                                                });
                                                notify(label, str);

                                                const isVal = Object.values(updated).some(ch => ch);
                                                setOpen(isVal);
                                                setComboChecked(updated);
                                            }}
                                        />
                                        <label>
                                            <span className="vis-checkbox" />
                                            {opt.label}
                                        </label>
                                    </comboBox>
                                );
                            }
                            // Child block
                            return (
                                <Block
                                    key={idx}
                                    element={opt}
                                    onBlockChange={onBlockChange}
                                    onSubmitDetected={onSubmitDetected}
                                />
                            );
                        })}
                    </form-content>
                    <br />
                </form-box>
            );
            break;

        case "option":
            // Nessun output
            break;

        case "submit":
            // Nessun output, ma onSubmitDetected e' gia' nel useEffect
            break;

        case "message":
            mainContent = (
                <div>
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: label }} />
                    <br />
                </div>
            );
            break;

        default:
            mainContent = (
                <div>
                    <b>default</b> {label}
                </div>
            );
            break;
    }

    /**
     * Se open===true => rendiamo i child
     * In aggiunta, se un child ha formScope="parameter", appare solo se localValue===child.name
     */
    let childBlocks = null;
    if (open) {
        childBlocks = childrens.map((child, idx) => {
            const scope = (child.formScope || "").toLowerCase();
            if (scope !== "parameter") {
                // child “normale”
                return (
                    <div style={{ marginLeft: "20px" }} key={idx}>
                        <Block
                            element={child}
                            onBlockChange={onBlockChange}
                            onSubmitDetected={onSubmitDetected}
                        />
                    </div>
                );
            } else {
                // param => appare solo se localValue===child.name
                if (localValue === child.name) {
                    return (
                        <div style={{ marginLeft: "20px" }} key={idx}>
                            <Block
                                element={child}
                                onBlockChange={onBlockChange}
                                onSubmitDetected={onSubmitDetected}
                            />
                        </div>
                    );
                }
                return null;
            }
        });
    }

    return (
        <div>
            {mainContent}
            {childBlocks}
        </div>
    );
}
