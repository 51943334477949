import styles from './Profile.module.css';
import {environment} from '@/commons/configs/Config';
import config from '@/commons/configs/Config.json';
import ApiInterface from "@/commons/api/ApiInterface.js";
import React, {ReactNode, useEffect, useState} from "react";
import {sha256Encrypt} from "@/commons/crypt/Encryption";
import Cookies from "js-cookie";
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";
import {useInfoBox} from "@/commons/infoBox/InfoBox";

interface SectionTitleProps {
    children: ReactNode;
}

interface TinyInfoProps{
    children: ReactNode;
}

interface ConnectedStatusProps {
    isConnected: boolean;
    children: ReactNode;
}

interface MoreInfoProps {
    children: ReactNode;
}

interface SectionButtonProps {
    children: string;
    action: React.MouseEventHandler<HTMLButtonElement>;
}

const Profile = () => {
    const {addInfo} = useInfoBox();
    let [mcInfo, setMcInfo] = useState<ReactNode>();
    let [twitchInfo, setTwitchInfo] = useState<ReactNode | null>();

    function loadTwitchConnection() {
        window.location.href = "https://id.twitch.tv/oauth2/authorize?client_id=" + config.twitch_app_id + "&redirect_uri=" + config.twitch_redirect_uri + "&response_type=code&scope=user:read:email&state"
    }

    const SectionTitle: React.FC<SectionTitleProps> = ({children}) => <span
        className={styles.sectionTitle}>{children}</span>;
    const ConnectedStatus: React.FC<ConnectedStatusProps> = ({isConnected, children}) => (
        <span
            className={isConnected ? styles.connected : `${styles.notConnected} ${styles.exclamationMark}`}>{children}</span>
    );
    const TinyInfo: React.FC<TinyInfoProps> = ({children}) => <span
        className={styles.sectionTinyInfo}>&nbsp;&nbsp;{children}</span>
    const MoreInfo: React.FC<MoreInfoProps> = ({children}) => <span className={styles.infoContainer}>{children}</span>;
    const ButtonInfo: React.FC<SectionButtonProps> = ({children, action}) =>
        <button onClick={action} className={styles.centerButton}>{children}</button>;

    async function buildPage(verified: boolean) {
        if (!verified) {
            setMcInfo(
                <>
                    <SectionTitle>Account non Verificato!</SectionTitle><br/>
                    <ConnectedStatus isConnected={false}>Email non Verificata</ConnectedStatus>
                    <MoreInfo>Sembra che l'indirizzo email associato al tuo account non sia stato verificato.<br/>
                        Per potel collegare il tuo account Minecraft / Twitch devi prima verificare l'email.<br/>
                        Se non hai ricevuto l'email di verifica puoi richiederne una nuova cliccando qui
                        sotto.</MoreInfo><br/>
                    <ButtonInfo action={(event) => {
                        let button = event.currentTarget;
                        button.classList.add("button--loading")
                        button.disabled = true;
                        ApiInterface.sendVerifyEmail().then((response) => {
                            if (response.status === 200) {
                                button.style.backgroundColor = '#307239';
                                button.disabled = true;
                                button.innerText = 'Inviata';
                                button.classList.remove("button--loading");
                            } else {
                                button.style.backgroundColor = '#7b3a3a';
                                button.disabled = false;
                                button.classList.remove("button--loading");
                                addInfo("alert", "Errore", "Errore interno durante l'invio dell'email", 7);
                            }
                        })
                    }}>Verifica Email</ButtonInfo><br/>
                </>
            )
            setTwitchInfo(null);
            return;
        }

        let mcStatus = await ApiInterface.fetchMinecraft();
        let mcJson = mcStatus.status === 200 ? await mcStatus.json() : null;
        let twitchStatus = await ApiInterface.fetchTwitch();
        let twitchJson = twitchStatus.status === 200 ? await twitchStatus.json() : null;

        setMcInfo(
            <>
                <SectionTitle>Collegamento Minecraft</SectionTitle><br/>
                {mcConnected(mcStatus, mcJson)}
            </>
        )
        setTwitchInfo(
            <>
                <SectionTitle>Collegamento Twitch</SectionTitle><br/>
                {await twitchConnected(mcStatus, mcJson, twitchStatus, twitchJson)}
            </>
        )
    }

    let mcConnected = (mcStatus: any, mcJson : any) => {
        let status : number = mcStatus.status

        switch (status) {
            case 200:
                return <>
                    <ConnectedStatus isConnected={true}>Account collegato</ConnectedStatus><br/>
                    <TinyInfo>{mcJson.username}</TinyInfo><br/><br/>
                    <MoreInfo>Se hai cambiato account minecraft puoi collegarne uno nuovo cliccando qui
                        sotto.</MoreInfo><br/><br/>
                    <ButtonInfo action={() => loadMinecraftToken()}>Cambia account Minecraft</ButtonInfo><br/><br/>
                </>;
            case 204:
                return <>
                    <ConnectedStatus isConnected={false}>Nessun Account collegato</ConnectedStatus><br/><br/>
                    <MoreInfo>Per collegare il tuo account minecraft all'account report basta copiare il token univoco
                        su minecraft ed eseugire il comando:<br/>
                        <span className={styles.goldColor}>/reports link [token]</span> in chat. <br/>
                    </MoreInfo><br/><br/>
                    <ButtonInfo action={() => loadMinecraftToken()}>Collega Minecraft</ButtonInfo><br/><br/>
                </>;
            default:
                return <ConnectedStatus isConnected={false}>Errore Interno</ConnectedStatus>;
        }
    }

    let twitchConnected = async (mcStatus: any, mcJson:any, twitchStatus: any, twitchJson: any) => {
        let status: number = twitchStatus.status
        let statusMC: number = mcStatus.status

        switch (statusMC) {
            case 200:
                let twLinkConnectionUrl = "https://twitchlink.erenblaze.it/connect?code="+mcJson.authCode+ "&redirect="+encodeURIComponent(document.location.href);

                const twLinkClick = () => {
                    window.location.href = twLinkConnectionUrl;
                };

                return <>
                {twitchJson ?
                    <ConnectedStatus isConnected={true}>Account preso in automatico</ConnectedStatus> :
                    <ConnectedStatus isConnected={false}>Nessun account collegato</ConnectedStatus>
                }
                    <br/>
                    <TinyInfo>{twitchJson ?
                        twitchJson.login : <></>}</TinyInfo>
                    <br/><br/>
                    <MoreInfo>{twitchJson ?
                        "Visto che hai un account Minecraft collegato il tuo account Twitch viene preso tramite il server EBLCraft." :
                        <>Non avendo nessun account Twitch collegato al tuo account Minecraft puoi collegarlo da qui sotto.<br/>
                            <button onClick={twLinkClick}>Collega Twitch Link</button>
                        </>}
                    </MoreInfo><br/><br/>
                </>;
            case 204:
                switch (status) {
                    case 200:
                        return <>
                            <ConnectedStatus isConnected={true}>Account collegato</ConnectedStatus><br/>
                            <TinyInfo>{twitchJson.login}</TinyInfo><br/><br/>
                            <MoreInfo>Se hai cambiato account Twitch puoi collegarne uno nuovo cliccando qui
                                sotto.</MoreInfo><br/><br/>
                            <ButtonInfo action={() => loadTwitchConnection()}>Collega Twitch</ButtonInfo>
                        </>;
                    default:
                        return <>
                            <ConnectedStatus isConnected={false}>Nessun account connesso</ConnectedStatus><br/><br/>
                            <MoreInfo>In alternativa collega solamente Twitch (Non influirà sul server EBLCraft).<br/>
                                Con l'account Twitch potrai accedere a contenuti extra destinati ai sub di ErenBlaze
                            </MoreInfo><br/><br/>
                            <ButtonInfo action={() => loadTwitchConnection()}>Collega Twitch</ButtonInfo>
                        </>
                }
            default:
                return <ConnectedStatus isConnected={false}>Errore Interno</ConnectedStatus>;
        }
    }

    let loadMinecraftBox = async () => {
        let verified: boolean;
        let response = await ApiInterface.getSelf();
        verified = response["emailVerified"];

        if (verified) {
            await buildPage(verified);
        } else {
            await buildPage(verified);
        }
    }

    let loadMinecraftToken = async () => {
        let token = await fetchMinecraftToken();
        if (token.status === 200) {
            let details = await token.json();
            let tokenValue = details["token"];

            navigator.clipboard.writeText("/reports link " + tokenValue).then(() => {
                addInfo("info", "Token copiato", "Il tuo token è:<br>"
                    + tokenValue + "<br>Esegui il seguente comando all'interno del server Minecraft." +
                    "<br>/reports link " + tokenValue + "<br> Lo abbiamo copiato per te negli appunti");
            });
        }
    }

    useEffect(() => {
        loadUserInfo();
        loadMinecraftBox().then(() => {
        });
    }, []);


    async function fetchMinecraftToken() {
        let userToken = Cookies.get(environment.tokenName);
        if (userToken === undefined) {
            userToken = "";
        }
        const result = fetch(environment.apiUrlOne + '/token/create/', {
            method: 'POST',
            headers: {
                'Authorization': userToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "scope": "minecraft_link"
            })
        });

        return await result
    }


    function loadUserInfo() {
        let email = (document.getElementById("email") as HTMLInputElement);
        let currentPassword = (document.getElementById("password") as HTMLInputElement);
        let newPassword = (document.getElementById("newPassword") as HTMLInputElement);
        let newPasswordConfirm = (document.getElementById("newPasswordConfirm") as HTMLInputElement);

        email.value = "";
        currentPassword.value = "";
        newPassword.value = "";
        newPasswordConfirm.value = "";
    }

    async function changeAccountData() {
        let changePassword = false;
        let emailChange = false;
        let email = (document.getElementById("email") as HTMLInputElement).value;
        let currentPassword = (document.getElementById("password") as HTMLInputElement).value;
        let newPassword = (document.getElementById("newPassword") as HTMLInputElement).value;
        let newPasswordConfirm = (document.getElementById("newPasswordConfirm") as HTMLInputElement).value;

        if (newPassword !== newPasswordConfirm) {
            addInfo("alert", "Errore", "Le due password non coincidono", 7);
            return;
        }
        if (newPassword !== "" && newPassword !== undefined) {
            changePassword = true;
            //L'utente ha inserito una nuova password
            if (newPassword.length < 8) {
                addInfo("alert", "Errore", "La password deve essere lunga almeno 8 caratteri", 7);
                return;
            }
            if (newPassword === currentPassword) {
                addInfo("alert", "Errore", "La nuova password deve essere diversa dalla vecchia", 7);
                return;
            }
            if (newPasswordConfirm !== newPassword) {
                addInfo("alert", "Errore", "Le due password non coincidono", 7);
                return;
            }
        }
        if (email !== "" && email !== undefined) {
            emailChange = true;
            //L'utente ha inserito una nuova email
            if (!email.includes("@")) {
                addInfo("alert", "Errore", "L'email inserita non è valida", 7);
                return;
            }
            if (!email.includes(".")) {
                addInfo("alert", "Errore", "L'email inserita non è valida", 7);
                return;
            }
            if (email.length < 5) {
                addInfo("alert", "Errore", "L'email inserita non è valida", 7);
                return;
            }
            if (email.includes(" ")) {
                addInfo("alert", "Errore", "L'email inserita non è valida", 7);
                return;
            }
        }

        if (changePassword || emailChange) {
            let body: any = {};
            let p = await sha256Encrypt(newPassword);
            if (changePassword) {
                body["newPassword"] = p;
            }
            if (emailChange) {
                body["email"] = email;
            }
            let cp = await sha256Encrypt(currentPassword);
            body["currentPassword"] = cp;
            await ApiInterface.changeAccountData(Cookies.get(environment.tokenName), body).then(async (response) => {
                if (response.status === 200) {
                    addInfo("info", "Dati aggiornati", "I tuoi dati sono stati aggiornati con successo", 7);
                    if (emailChange) {
                        await ApiInterface.fetchEmailChange(Cookies.get(environment.tokenName), cp);
                    }
                    loadUserInfo();
                } else if (response.status === 409) {
                    addInfo("alert", "Errore", "Email già registrata sul sito!", 7);
                    loadUserInfo();
                } else if (response.status === 418) {
                    let json = await response.json();
                    if (json != null && json !== "") {
                        addInfo(json["object"]["alert"], json["object"]["title"], json["object"]["message"], 7);
                    }
                } else {
                    addInfo("alert", "Errore", "Errore interno, verifica di essere loggato e di aver inserito la password corretta!", 7);
                }
            })
        } else {
            addInfo("info", "Nessuna modifica", "Non hai inserito alcuna modifica", 7);
            return;
        }
    }

    return (
        <>
            <Background/>
            <div className={"page__body"}>
                <Header marginTop={2} marginBottom={2}/>
                <div className={"page__content"}>
                    <div className={styles.profileMainBox}>
                        <div className={styles.profileGrid}>
                            <div className={styles.profileContainer}>
                                <div>
                                    <div className={styles.connectionsInfo}>
                                        {mcInfo}
                                        {twitchInfo !== null ? <>
                                            <br/><hr/><br/><br/>
                                            {twitchInfo}</> : <></>}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.connectionButton}>
                                <div id={"minecraftLink"}>
                                </div>
                            </div>
                            <div className={styles.columnBorder}>

                            </div>
                            <div className={styles.infoContainer}>
                                <div className={styles.infoChange}>
                                    <div className={styles.sectionTitle}>
                                        Modifica le credenziali
                                    </div>
                                    <div>
                                        <div className={styles.accountInfoFormRow}>
                                            <label>E-Mail:</label><input type={"email"} id={"email"}
                                                                         autoComplete={"off"}/>
                                        </div>
                                        <div className={styles.accountInfoFormRow}>
                                            <label>Nuova<br/>password:</label><input type={"password"}
                                                                                     id={"newPassword"}
                                                                                     autoComplete={"off"}/>
                                        </div>
                                        <div className={styles.accountInfoFormRow}>
                                            <label>Conferma<br/>password:</label><input type={"password"}
                                                                                        id={"newPasswordConfirm"}
                                                                                        autoComplete={"off"}/>
                                        </div>
                                        <div className={styles.accountInfoFormRow}>
                                            <label>Password<br/>attuale:</label><input type={"password"} id={"password"}
                                                                                       autoComplete={"off"}/>
                                        </div>
                                    </div>
                                    <button className={styles.centerButton} onClick={changeAccountData}>Salva modifiche
                                    </button>
                                </div>
                            </div>

                            <div className={styles.infoButton}>
                                <div>
                                </div>
                            </div>
                        </div>

                        <br/><br/>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default Profile;