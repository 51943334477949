import React, { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';
import styles from './InfoBox.module.css';
import { v4 as uuidv4 } from 'uuid';
import { flushSync } from 'react-dom';

type InfoType = 'warning' | 'alert' | 'success' | 'info';
type Status = 'creating' | 'created' | 'remove' | 'removing' | 'removed' | 'show';

interface Info {
    id: string;
    type: InfoType;
    title: string;
    text: string;
    status: Status;
    persistent?: boolean;
    progressBar?: boolean;
}

type Action =
    | { type: 'add'; payload: Info }
    | { type: 'remove'; payload: string }
    | { type: 'update'; payload: { id: string; status: Status } };

type State = Record<string, Info>;

type InfoBoxContextType = {
    info: Info[];
    addInfo: (
        type: InfoType,
        title: string,
        text: string,
        autoRemoveTime?: number
    ) => void;
    addPersistentInfo: (type: InfoType, title: string, text: string) => void;
    updatePersistentInfo: (changes: {
        text?: string;
        title?: string;
        type?: InfoType;
        destroyIn?: number;
    }) => void;
    removePersistentInfo: () => void;
    removeInfo: (id: string) => void;
    changeStatus: (id: string, status: Status) => void;
};

const InfoBoxContext = createContext<InfoBoxContextType | undefined>(undefined);

const initialState: State = {};

function infoBoxReducer(state: State, action: Action): State {
    switch (action.type) {
        case 'add':
            return { ...state, [action.payload.id]: action.payload };

        case 'remove': {
            const newState = { ...state };
            delete newState[action.payload];
            return newState;
        }

        case 'update': {
            const existing = state[action.payload.id];
            if (!existing) return state;
            return {
                ...state,
                [action.payload.id]: { ...existing, status: action.payload.status }
            };
        }

        default:
            throw new Error('Unhandled action type');
    }
}

let globalAddInfo: InfoBoxContextType['addInfo'];
let globalAddPersistentInfo: InfoBoxContextType['addPersistentInfo'];
let globalUpdatePersistentInfo: InfoBoxContextType['updatePersistentInfo'];
let globalRemovePersistentInfo: InfoBoxContextType['removePersistentInfo'];

export const InfoBoxProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(infoBoxReducer, initialState);

    const addInfo: InfoBoxContextType['addInfo'] = (
        type,
        title,
        text,
        autoRemoveTime = 0
    ) => {
        const id = uuidv4();
        flushSync(() => {
            dispatch({
                type: 'add',
                payload: { id, type, title, text, status: 'creating' }
            });
        });

        flushSync(() => {
            dispatch({
                type: 'update',
                payload: { id, status: 'created' }
            });
        });

        if (autoRemoveTime > 0) {
            setTimeout(() => {
                flushSync(() => {
                    dispatch({
                        type: 'update',
                        payload: { id, status: 'remove' }
                    });
                });
            }, autoRemoveTime * 1000);
        }
    };

    const addPersistentInfo: InfoBoxContextType['addPersistentInfo'] = (
        type,
        title,
        text
    ) => {
        const id = uuidv4();
        flushSync(() => {
            dispatch({
                type: 'add',
                payload: {
                    id,
                    type,
                    title,
                    text,
                    status: 'creating',
                    persistent: true,
                    progressBar: true
                }
            });
        });

        flushSync(() => {
            dispatch({
                type: 'update',
                payload: { id, status: 'created' }
            });
        });
    };

    const updatePersistentInfo: InfoBoxContextType['updatePersistentInfo'] = (changes) => {
        console.log('Trying');
        const persistentEntry = Object.values(state).find((info) => info.persistent);

        console.log('pers', persistentEntry);
        if (!persistentEntry) return;

        const { id } = persistentEntry;

        if (changes.destroyIn) {
            flushSync(() => {
                dispatch({ type: 'remove', payload: id });
                addInfo(
                    changes.type || 'info',
                    changes.title || 'Default Title',
                    changes.text || 'Default Text',
                    changes.destroyIn
                );
            });
        } else {
            flushSync(() => {
                dispatch({
                    type: 'add',
                    payload: {
                        ...persistentEntry,
                        text: changes.text || persistentEntry.text,
                        title: changes.title || persistentEntry.title,
                        type: changes.type || persistentEntry.type
                    }
                });
            });
        }
    };

    const removePersistentInfo: InfoBoxContextType['removePersistentInfo'] = () => {
        const persistentEntry = Object.values(state).find((info) => info.persistent);

        if (persistentEntry) {
            const { id } = persistentEntry;
            flushSync(() => {
                dispatch({ type: 'update', payload: { id, status: 'removing' } });
            });
            setTimeout(() => {
                flushSync(() => {
                    dispatch({ type: 'remove', payload: id });
                });
            }, 500);
        }
    };

    const removeInfo: InfoBoxContextType['removeInfo'] = (id) => {
        flushSync(() => {
            dispatch({ type: 'update', payload: { id, status: 'removing' } });
        });
        setTimeout(() => {
            flushSync(() => {
                dispatch({ type: 'remove', payload: id });
            });
        }, 500);
    };

    const changeStatus: InfoBoxContextType['changeStatus'] = (id, status) => {
        flushSync(() => {
            dispatch({
                type: 'update',
                payload: { id, status }
            });
        });
    };

    useEffect(() => {
        globalAddInfo = addInfo;
        globalAddPersistentInfo = addPersistentInfo;
        globalUpdatePersistentInfo = updatePersistentInfo;
        globalRemovePersistentInfo = removePersistentInfo;

        (window as any).addInfo = addInfo;
        (window as any).addPersistentInfo = addPersistentInfo;
        (window as any).updatePersistentInfo = updatePersistentInfo;
        (window as any).removePersistentInfo = removePersistentInfo;
    }, [addInfo, addPersistentInfo, updatePersistentInfo, removePersistentInfo]);

    return (
        <InfoBoxContext.Provider
            value={{
                info: Object.values(state),
                addInfo,
                addPersistentInfo,
                updatePersistentInfo,
                removePersistentInfo,
                removeInfo,
                changeStatus
            }}
        >
            {children}
        </InfoBoxContext.Provider>
    );
};

export const useInfoBox = () => {
    const context = useContext(InfoBoxContext);
    if (!context) {
        throw new Error('useInfoBox must be used within an InfoBoxProvider');
    }
    return context;
};

export const useGlobalInfoBox = () => ({
    addInfo: globalAddInfo,
    addPersistentInfo: globalAddPersistentInfo,
    updatePersistentInfo: globalUpdatePersistentInfo,
    removePersistentInfo: globalRemovePersistentInfo
});

export const InfoBox = () => {
    const { info, removeInfo, changeStatus } = useInfoBox();

    useEffect(() => {
        info.forEach((item) => {
            if (item.status === 'created') {
                changeStatus(item.id, 'show');
            }
            if (item.status === 'remove') {
                changeStatus(item.id, 'removing');
                setTimeout(() => removeInfo(item.id), 1000);
            }
        });
    }, [info, changeStatus, removeInfo]);

    // Aggiungi un effetto per bloccare il reload/cambio pagina se c'è un messaggio persistente
    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            const hasPersistent = info.some((item) => item.persistent);
            if (hasPersistent) {
                window.addInfo(
                    'warning',
                    'Attenzione',
                    'Non puoi uscire dalla pagina durante l\'elaborazione!',
                    10
                );
                // Previeni il caricamento della nuova pagina
                e.preventDefault();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [info]);

    const getClass = (infoItem: Info) => {
        switch (infoItem.status) {
            case 'show':
                return styles.activeBox;
            case 'removing':
            case 'removed':
                return styles.hidedBox;
            default:
                return '';
        }
    };

    const getTypeClass = (infoItem: Info) => {
        switch (infoItem.type) {
            case 'warning':
                return styles.warning;
            case 'alert':
                return styles.alert;
            case 'success':
                return styles.success;
            case 'info':
            default:
                return styles.info;
        }
    };

    const getText = (text: string) => text.replaceAll('<br>', '\n');

    return (
        <div className={styles.infoBox__container}>
            {info.map((infoItem) => (
                <div
                    key={infoItem.id}
                    className={`${styles.infoBox__info} ${getClass(infoItem)} ${getTypeClass(infoItem)}`}
                >
                    <span className={styles.title}>{infoItem.title}</span>
                    <span className={styles.text}>
                        <pre>{getText(infoItem.text)}</pre>
                    </span>

                    {infoItem.progressBar && (
                        <div className={`${styles.progressBar} ${getTypeClass(infoItem)}`}/>
                    )}

                    {!infoItem.persistent && (
                        <div
                            className={styles.closeBox}
                            onClick={() => removeInfo(infoItem.id)}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};
