// Tickets.js
import './Tickets.css';
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";
import Editor from '@/reports/editor/Editor';

import ApiInterface from "@/commons/api/ApiInterface";
import { environment } from '@/commons/configs/Config';
import Categories from "@/commons/objects/category/Categories";
import Category from "@/commons/objects/category/Category";
import FormModel from "@/commons/objects/form/FormModel";
import { getReportText } from "@/commons/tickets/TicketsCommons";

// Import del FormBuilder
import FormElementBuilder from "@/commons/objects/form/FormElementBuilder";
// Oppure se lo chiami "FormBuilder" e il file è "FormBuilder.js", import FormBuilder from...

function Tickets() {
    let isStaff, hasMc, isSub, isVip, isEmailVerified;
    let allCats = new Categories();

    const childMap = new Map();
    const changed = new Map();
    const portals = new Map();
    const roots = new Map();

    // Stato per l’editor
    const [value, setValue] = useState('');

    // Stato per la categoria selezionata
    const [selCat, setSelCat] = useState(0);

    // Stato per mostrare editor + tasto “Invia”
    const [showBox, setShowBox] = useState(false);

    // Stato per i dati compilati dall’utente (FormElementBuilder)
    const [formData, setFormData] = useState(null);

    // Stato con la formElement (da cat.formattedForm)
    const [formElement, setFormElement] = useState(null);

    // useEffect principale, come volevi tu
    useEffect(() => {
        loadData().then(() => {
            loadInfoBox();
            loadAllCategories().then(() => {
                checkCategory();
            });
        });
    }, []);

    async function loadData() {
        isStaff = await ApiInterface.isStaffer();
        hasMc = await ApiInterface.hasMc();
        isSub = await ApiInterface.isSub();
        isVip = await ApiInterface.isVip();
        isEmailVerified = await ApiInterface.isEmailVerified();
    }

    async function loadAllCategories() {
        let cats = await getCategories();
        if (cats.status === 200) {
            let json = await cats.json();
            allCats = new Categories();
            allCats.addAllCat(json);

            // Filtra e ordina
            const hiddenCat = allCats.clone();
            const visibleCat = allCats.clone();
            hiddenCat.getAllByHidden(true);
            visibleCat.getAllByHidden(false);
            hiddenCat.sortCategoriesByOrderId();
            visibleCat.sortCategoriesByOrderId();

            loadCategories(visibleCat);
        }
    }

    function loadInfoBox() {
        if (!isEmailVerified) {
            let element = document.getElementById("Tickets_infoBox");
            if (element) {
                element.innerHTML = `Per poter fare un report verifica la tua email.<br/>
          Per verificare l'indirizzo email vai nella tua <a href='/profile'>Area Personale</a> e segui i passaggi.`;
            }
        } else if (!hasMc) {
            let element = document.getElementById("Tickets_infoBox");
            if (element) {
                element.innerHTML = `*Alcune categorie sono disabilitate in quanto non hai un account Minecraft collegato.<br/>
          Per collegare l'account vai nella sezione <a href='/profile'>Area Personale</a>.<br/>
          Report su questioni del server Minecraft potrebbero essere chiusi se manca l'account.`;
            }
        }
    }

    async function getCategories() {
        const userToken = Cookies.get(environment.tokenName);
        return fetch(environment.apiUrlOne + '/category/getAll/', {
            method: 'GET',
            headers: {
                'Authorization': userToken
            }
        });
    }

    function loadCategories(catToLoad) {
        const categorySelection = document.getElementById("categorySelection");
        if (!categorySelection) return;
        categorySelection.innerHTML = "";

        let option = document.createElement("option");
        option.setAttribute("value", "0");
        option.innerText = "Seleziona una categoria";
        option.disabled = true;
        option.selected = true;
        categorySelection.appendChild(option);

        for (const cat of catToLoad.categories) {
            const option = document.createElement("option");
            option.setAttribute("value", cat.id);
            option.innerText = cat.name;

            if (cat.orderId <= -1) {
                continue;
            }
            if (isStaff) {
                switch (cat.usable.toLowerCase()) {
                    case "separator":
                        option.disabled = true;
                        break;
                    default:
                        break;
                }
            } else {
                if (!isEmailVerified) {
                    option.disabled = true;
                    option.classList.add("disabledOption");
                    option.innerText += "*";
                } else {
                    switch (cat.usable.toLowerCase()) {
                        case "separator":
                            option.disabled = true;
                            break;
                        case "hidden":
                            continue;
                        case "everyone":
                            break;
                        case "staff":
                            if (!isStaff) continue;
                            break;
                        case "linked":
                            if (!hasMc) {
                                option.disabled = true;
                                option.classList.add("disabledOption");
                                option.innerText += "*";
                            }
                            break;
                        case "subscribers":
                            if (!isSub) {
                                option.disabled = true;
                                option.classList.add("disabledOption");
                                option.innerText += "*";
                            }
                            break;
                        case "vips":
                            if (!isVip) {
                                continue;
                            }
                            break;
                        default:
                            option.disabled = true;
                            break;
                    }
                }
            }
            categorySelection.appendChild(option);
        }
        categorySelection.addEventListener("change", handleCategoryChange);
    }

    function checkCategory() {
        let url = new URL(window.location.href);
        let categoryValues = url.searchParams.getAll('defaultCategory');
        for (let i = 0; i < categoryValues.length; i++) {
            let category = categoryValues[i];
            let selection = document.getElementById("categorySelection");
            if (!selection) continue;
            selection.value = category;
            selection.dispatchEvent(new Event('change'));
            url.searchParams.delete('defaultCategory');
            window.history.replaceState({}, '', url);
        }
    }

    function handleCategoryChange(e) {
        setShowBox(false);
        const val = e.target.value;
        setSelCat(val);

        const selectedCat = allCats.getById(val);
        const Cat = new Category(selectedCat);

        if (!Cat.formattedForm) {
            setFormElement(null);
            let doc = document.getElementById("form-Main");
            if (doc) {
                doc.innerHTML = `<div class="alert" role="alert">
          <p>Nessun form associato a questa categoria. Se pensi sia un errore, segnalacelo.</p><br/>
          <p class="smaller-text">
            Consulta la sezione Info nella Home o verifica su Telegram.
            Se il problema persiste, contattaci!
          </p>
        </div>`;
            }
            return;
        }

        // FormModel
        const formValue = new FormModel(Cat.formattedForm);
        setFormElement(formValue);
    }

    function getForm() {
        // Non usiamo più la vecchia logica DOM “getElementsByTagName(form-box)”
        // adesso i dati li riceviamo da formData.
        // Se vuoi mantenere la vecchia, integrala,
        // ma con FormBuilder ci conviene usare “formData”.
        return "";
    }

    async function uploadReport() {
        const button = document.getElementById("submitBox");
        if (!button) return;
        window.addPersistentInfo("info", "Apro il ticket", "Stiamo aprendo un ticket per te!");

        button.disabled = true;
        button.innerHTML = "Apro il ticket...";
        button.classList.add("button--loading");

        const titleEl = document.getElementById("reportTitle");
        if (!titleEl) return;
        const title = titleEl.value;
        if (!title) {
            button.disabled = false;
            button.innerText = 'Invia';
            button.classList.remove("button--loading");
            window.updatePersistentInfo({
                type: "alert",
                title: "Dati non validi",
                text: "Il titolo non può essere vuoto!",
                destroyIn: 10
            });
            return null;
        }

        // formData => { "username": "...", "combo": "pref1=Yes", ecc. }
        let text = "";
        if (formData) {
            Object.entries(formData).forEach(([k, v]) => {
                text += `${k}: ${v}<br/>`;
            });
            text += `<br/>`;
        }
        if(value){
            text += `<b>Descrizione: </b><br/><br/>`;
        }
        const combined = await getReportText(value, text);

        const userToken = Cookies.get(environment.tokenName);
        window.updatePersistentInfo({
            type: "info",
            title: "Carico il report",
            text: "Stiamo caricando il report!",
            destroyIn: 10
        });

        fetch(environment.apiUrlOne + '/posts/create/', {
            method: 'POST',
            headers: {
                'Authorization': userToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                title: title,
                categoryId: selCat,
                content: combined,
                status: "open",
            })
        }).then(async (response) => {
            if (response.status === 200) {
                const details = await response.json();
                let id = details.id;
                button.style.backgroundColor = '#307239';
                button.disabled = true;
                button.innerText = 'Inviato';
                button.classList.remove("button--loading");
                window.updatePersistentInfo({
                    type: "success",
                    title: "Report caricato",
                    text: "Il report è stato caricato!",
                    destroyIn: 10
                });
                window.location.href = environment.reportsUrl + "post/" + id;
            } else if (response.status === 418) {
                let json = await response.json();
                if (json) {
                    window.updatePersistentInfo({
                        type: json["object"]["type"],
                        title: json["object"]["title"],
                        text: json["object"]["text"],
                        destroyIn: 7
                    });
                }
            } else {
                button.style.backgroundColor = '#7b3a3a';
                button.disabled = false;
                button.classList.remove("button--loading");
                window.updatePersistentInfo({
                    type: "alert",
                    title: "Errore",
                    text: "Si è verificato un errore durante la pubblicazione del post. Se persiste, contatta un admin!",
                    destroyIn: 10
                });
            }
        }).catch((error) => {
            button.style.backgroundColor = '#7b3a3a';
            button.disabled = false;
            button.classList.remove("button--loading");
            window.updatePersistentInfo({
                type: "alert",
                title: "Errore",
                text: "Si è verificato un errore durante la pubblicazione del post. Se persiste, contatta un admin!",
                destroyIn: 10
            });
            console.error('Error:', error);
        });
        return null;
    }

    return (
        <>
            <meta name="description" content="Hai bisogno di aiuto? Apri ora un Report su EBLCraft Web!" />
            <Background />
            <div className="page__body">
                <Header marginTop={2} marginBottom={2} />
                <div className="page__content">
                    <div className="Tickets_ReportsMainBox"><br />
                        <span className="Tickets_ReportsTitle">
              Crea un report
            </span><br /><br />
                        <span className="Tickets_infoBox" id="Tickets_infoBox"></span>

                        <div className="Tickets_reportSuperBox">
                            <div id="reportsTable" className="Tickets_ReportsTableBox">
                                <div className="Tickets_mainFormInfo">
                                    <div>
                                        <label>Titolo:</label><br />
                                        <input type="text" id="reportTitle" minLength={3} maxLength={30} /><br /><br />
                                    </div>
                                    <div>
                                        <label>Seleziona Categoria:</label><br />
                                        <select id="categorySelection" defaultValue={1}>
                                            <option disabled value={1}>Seleziona una categoria</option>
                                        </select>
                                    </div>
                                </div>

                                {/* Qui montiamo i form con "FormElementBuilder" */}
                                <div id="form-Main">
                                    {formElement && (
                                        <FormElementBuilder
                                            formElement={formElement}
                                            onChange={(data) => {
                                                setFormData(data);
                                            }}
                                            onSubmitDetected={(submitName) => {
                                                // Se appare un "submit", abilitiamo editor+tasto
                                                setShowBox(true);
                                            }}
                                        />
                                    )}
                                </div>
                            </div>

                            {/* Editor e tasto Invia solo se showBox=true */}
                            {showBox && (
                                <div id="infoBoxContainer" className="tickets__moreInfoBox">
                                    <label>Informazioni aggiuntive:</label><br />
                                    <div id="formDescription">
                                        <Editor value={value} onChange={setValue} />
                                    </div>
                                    <button
                                        id="submitBox"
                                        onClick={uploadReport}
                                    >
                                        Invia
                                    </button>
                                </div>
                            )}
                        </div>
                        <br />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Tickets;
