import React, {useEffect, useRef, useState} from "react";
import Editor from "@/reports/editor/Editor";
import ServerPreview from "@/www/pages/server/objects/Main/ServerPreview";
import "./ServersList.css";
import FileHandler from "@/www/pages/server/objects/file/FileHandler";
import ApiInterface from "@/commons/api/ApiInterface";

async function saveServerPage(serverObj, name) {
    await FileHandler.saveFile(serverObj, name)
}

/** //TODO: completare la logica di eliminazione del server */
function deleteServer(name) {

}


class ServerObj {
    constructor(title, description, preview, name) {
        this.title = title;
        this.description = description;
        this.preview = preview ? new ServerPreview(preview.title, preview.description) : null;

        // Mantenere “name” per salvare/eliminare il file
        this.name = name;
    }

    renderer() {
        const WrappedComponent = () => {
            const [hasPermission, setStaffPermission] = useState(false);
            // Stati per la “page” e la “preview”
            const [isLoading, setIsLoading] = useState(false);
            const [isEditingPage, setIsEditingPage] = useState(false);
            const [isPreviewingPage, setIsPreviewingPage] = useState(false);

            const [isEditingPreview, setIsEditingPreview] = useState(false);
            const [isPreviewingPreview, setIsPreviewingPreview] = useState(false);

            // Stato per la modifica del “title”
            const [isEditingTitle, setIsEditingTitle] = useState(false);
            const [tempTitle, setTempTitle] = useState(this.title);

            // Contenuto “page” e “preview”
            const [pageHtml, setPageHtml] = useState(this.description);
            const [previewHtml, setPreviewHtml] = useState(this.preview?.description ?? "");

            const editorRef = useRef(null);

            async function lostStaffStatus() {
                setStaffPermission(await ApiInterface.hasPermission("admin.page.servers.edit"))
            }

            lostStaffStatus();

            /*******************************************
             * AZIONI SU “PAGE”
             *******************************************/
            const handleEditPage = () => {
                setIsEditingPage(true);
                setIsPreviewingPage(false);

                setIsEditingPreview(false);
                setIsPreviewingPreview(false);
                setIsEditingTitle(false);
            };

            const handlePreviewPage = () => {
                setIsEditingPage(false);
                setIsPreviewingPage(true);
            };

            const handleBackToEditPage = () => {
                setIsEditingPage(true);
                setIsPreviewingPage(false);
            };

            const handleSavePage = () => {
                setIsLoading(true);
                handleLoadImages(pageHtml).then(newHtml => {
                    this.description = newHtml;
                    setIsEditingPage(false);
                    setIsPreviewingPage(false);
                    saveServerPage(this, this.name);
                    setIsLoading(false);
                })
            };

            /*******************************************
             * AZIONI SU “PREVIEW”
             *******************************************/
            const handleEditPreview = () => {
                setIsEditingPreview(true);
                setIsPreviewingPreview(false);

                setIsEditingPage(false);
                setIsPreviewingPage(false);
                setIsEditingTitle(false);
            };

            const handlePreviewPreview = () => {
                setIsEditingPreview(false);
                setIsPreviewingPreview(true);
            };

            const handleBackToEditPreview = () => {
                setIsEditingPreview(true);
                setIsPreviewingPreview(false);
            };

            const handleSavePreview = () => {
                setIsLoading(true);

                handleLoadImages(previewHtml).then(newHtml => {
                    this.preview.description = newHtml;
                    setIsEditingPreview(false);
                    setIsPreviewingPreview(false);
                    saveServerPage(this, this.name);
                    setIsLoading(false);
                })
            };

            /*******************************************
             * AZIONI SU “TITLE” (NOME VISIBILE)
             *******************************************/
            const handleEditTitle = () => {
                setIsEditingTitle(true);

                setIsEditingPage(false);
                setIsPreviewingPage(false);
                setIsEditingPreview(false);
                setIsPreviewingPreview(false);
            };

            const handleSaveTitle = () => {
                this.title = tempTitle;
                saveServerPage(this, this.name)
                setIsEditingTitle(false);
            };

            /*******************************************
             * ELIMINAZIONE SERVER
             *******************************************/
            const handleDelete = () => {
                deleteServer(this);
            };
            /*******************************************
             * Gestione Immagini
             *******************************************/
            const handleLoadImages = async (text) => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(text, 'text/html');
                const imgTags = doc.getElementsByTagName('img');
                window.addPersistentInfo("info", "Carico le immagini", "Stiamo caricando le tue immagini");

                for (let i = 0; i < imgTags.length; i++) {
                    window.updatePersistentInfo({type:"info", title:"Carico le immagini", text:"Stiamo caricando la tua immagine " + (i + 1) + "/" + imgTags.length})
                    const img = imgTags[i];
                    const src = img.getAttribute('src');

                    // Verifica se l'immagine è una data URI
                    if (src.startsWith('data:image')) {
                        try {
                            // Estrai il tipo MIME e i dati base64
                            const dataURI = src;
                            const mimeType = dataURI.split(',')[0].split(':')[1].split(';')[0];
                            const bytes = window.atob(dataURI.split(',')[1]);
                            const data = new Uint8Array(bytes.length);

                            // Converte la stringa base64 in binario
                            for (let j = 0; j < bytes.length; j++) {
                                data[j] = bytes.charCodeAt(j);
                            }

                            const extension = mimeType.split("/")[1];
                            const file = new File([data], `image.${extension}`, {type: mimeType});

                            // Usa la funzione uploadFile di ApiInterface per caricare l'immagine
                            const imageResponse = await ApiInterface.uploadFile(file, "servers/attachments/images", false, true);

                            if (imageResponse) {
                                img.setAttribute('src', imageResponse); // Imposta l'URL ritornato come src dell'immagine
                            } else {
                                console.error("Errore durante il caricamento dell'immagine!");
                            }
                        } catch (err) {
                            console.error("Errore durante la conversione o il caricamento dell'immagine:", err);
                        }
                    }
                }


                window.updatePersistentInfo({
                    destroyIn: 10,
                    title: "Carico le immagini",
                    type: "success",
                    text: "Caricamento completato delle immagini"
                })

                return doc.documentElement.innerHTML;
            }


            const loadImages = async (content) => {
                console.log("LOAD IMAGES")
                const parser = new DOMParser();
                const doc = parser.parseFromString(content, "text/html");
                const imgTags = doc.querySelectorAll("img");

                let hasChanges = false;
                console.log(imgTags)

                for (const img of imgTags) {
                    const src = img.getAttribute("src");

                    if (src && !src.startsWith("http") && !src.startsWith("data:image")) {

                        try {
                            console.log("Searching for image: ", src)
                            const imageRes = await ApiInterface.getFileUrl(src);

                            if (imageRes !== null) {
                                img.setAttribute("src", imageRes);
                                hasChanges = true;
                            }
                            console.log("Found ", imageRes)
                        } catch (error) {
                            console.error("Errore durante il caricamento dell'immagine: ", error);
                        }
                    }
                }

                if (hasChanges) {
                    const newHtml = doc.documentElement.innerHTML;
                    return newHtml;
                }
                return null;
            };

            const loadPage = async () => {
                let newHtml = await loadImages(pageHtml);
                if(newHtml === null) return
                setPageHtml(newHtml);
            }

            const loadPreview = async () => {
                let newHtml = await loadImages(previewHtml);
                if(newHtml === null) return
                setPreviewHtml(newHtml);
            }

            useEffect(() => {
                if (!pageHtml) return;
                loadPage()
            }, [pageHtml]);

            useEffect(() => {
                if (!previewHtml) return;
                loadPreview()
                console.log("PREVIEW", previewHtml)
            }, [previewHtml]);

            /*******************************************
             * VISUALIZZAZIONE PULSANTI
             *******************************************/
            let showBaseButtons = hasPermission && !isEditingPage && !isPreviewingPage && !isEditingPreview && !isPreviewingPreview && !isEditingTitle;

            let showEditingPageButtons = hasPermission && isEditingPage;
            let showPreviewPageButtons = hasPermission && isPreviewingPage;

            let showEditingPreviewButtons = hasPermission && isEditingPreview;
            let showPreviewPreviewButtons = hasPermission && isPreviewingPreview;
            useEffect(() => {
                showBaseButtons = hasPermission && !isEditingPage && !isPreviewingPage && !isEditingPreview && !isPreviewingPreview && !isEditingTitle;

                showEditingPageButtons = hasPermission && isEditingPage;
                showPreviewPageButtons = hasPermission && isPreviewingPage;

                showEditingPreviewButtons = hasPermission && isEditingPreview;
                showPreviewPreviewButtons = hasPermission && isPreviewingPreview;
            }, [hasPermission])

            return (<div className="serverPage__modComplete" style={{position: "relative"}}>
                {hasPermission && (<div className="serverPage__editButtons">
                    {showBaseButtons && !isLoading && (<>
                        <button onClick={handleEditTitle}>Modifica Nome</button>
                        <button onClick={handleEditPreview}>Edit Preview</button>
                        <button onClick={handleEditPage}>Edit Page</button>
                        <button onClick={handleDelete}>Elimina</button>
                    </>)}

                    {showEditingPageButtons && !isLoading && (<>
                        <button onClick={handlePreviewPage}>Anteprima</button>
                        <button onClick={handleSavePage}>Salva</button>
                    </>)}
                    {showPreviewPageButtons && !isLoading && (<>
                        <button onClick={handleBackToEditPage}>Edit</button>
                        <button onClick={handleSavePage}>Salva</button>
                    </>)}

                    {showEditingPreviewButtons && !isLoading && (<>
                        <button onClick={handlePreviewPreview}>Anteprima</button>
                        <button onClick={handleSavePreview}>Salva</button>
                    </>)}
                    {showPreviewPreviewButtons && !isLoading && (<>
                        <button onClick={handleBackToEditPreview}>Edit</button>
                        <button onClick={handleSavePreview}>Salva</button>
                    </>)}

                    {isEditingTitle && (<>
                        <input
                            type="text"
                            value={tempTitle}
                            onChange={(e) => setTempTitle(e.target.value)}
                        />
                        <button onClick={handleSaveTitle}>Salva</button>
                        <button onClick={() => setIsEditingTitle(false)}>Annulla</button>
                    </>)}
                </div>)}

                {/* Editor per la pagina */}
                {isEditingPage && (<div className="editor__container">
                    <Editor
                        value={pageHtml}
                        onChange={(value) => setPageHtml(value)}
                    />
                </div>)}

                {/* Editor per la preview */}
                {isEditingPreview && (<div className="editor__container">
                    <Editor
                        value={previewHtml}
                        onChange={(value) => setPreviewHtml(value)}
                    />
                </div>)}

                {/* Anteprima pagina */}
                {isPreviewingPage && (<div className="serverPage__mod_text">
                    <span className="serverPage__mod_title">{this.title}</span>
                    <span
                        className="serverPage__mod_description"
                        dangerouslySetInnerHTML={{__html: pageHtml}}
                    />
                </div>)}

                {/* Anteprima preview */}
                {isPreviewingPreview && (<div className="serverPage__mod_text">
                    <span className="serverPage__mod_title">{this.title}</span>
                    <span
                        className="serverPage__mod_description"
                        dangerouslySetInnerHTML={{__html: previewHtml}}
                    />
                </div>)}

                {/* Vista normale se non si modifica né “page” né “preview” né “title” */}
                {!isEditingPage && !isPreviewingPage && !isEditingPreview && !isPreviewingPreview && !isEditingTitle && !isLoading && (
                    <div className="serverPage__mod_text">
                        <span className="serverPage__mod_bigtitle">{this.title}</span>
                        <span
                            className="serverPage__mod_description"
                            dangerouslySetInnerHTML={{__html: pageHtml}}
                        />
                    </div>)}
            </div>);
        };

        return <WrappedComponent/>;
    }

    previewRender() {
        if (this.preview) {
            return this.preview?.renderer();
        } else {
            this.preview = new ServerPreview(this.title, this.description)
            return this.preview?.renderer();
        }
    }
}

export default ServerObj;