import React, {useEffect, useState} from "react";
import './GroupsManager.css';
import ApiInterface from "@/commons/api/ApiInterface";
import {useInfoBox} from "@/commons/infoBox/InfoBox";

import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";
import GroupObject from "./objects/GroupObject";
import Redirects from "@/commons/utils/Redirects";
import {elaborateData} from "@/commons/api/helper/Reponses";

const GroupsManager = () => {
    const {addInfo} = useInfoBox();
    const [isCreatingGroup, setIsCreatingGroup] = useState(false);
    const [newGroupData, setNewGroupData] = useState({name: "", description: "", power: 0, staffer: false});
    const [getTotalGroups, setTotalGroups] = useState(0);
    const [staticPermissions, setStaticPermissions] = useState([]);
    const [dynamicPermissions, setDynamicPermissions] = useState([]);
    const [groupsObjects, setGroupsObjects] = useState([]);
    const loadedImages = new Set(); // Set per tracciare i gruppi già caricati

    // Ordina i gruppi per "power" (dal più alto al più basso)
    const sortGroupsByPower = (groups) => {
        return [...groups].sort((a, b) => b.groupPower - a.groupPower);
    };

    const saveDescription = (groupId, newDescription) => {
        setDescription(groupId, newDescription);
        toggleEditingState(groupId, "description", false);
    };

    const savePower = (groupId, newPower) => {
        setPower(groupId, newPower);
        toggleEditingState(groupId, "power", false);
    };

    const setDescription = async (groupId, newDescription) => {
        await ApiInterface.elaborateData(await ApiInterface.setDescription(groupId, newDescription), addInfo);
        const updatedGroups = groupsObjects.map(group =>
            group.id === groupId ? {...group, groupDescription: newDescription} : group
        );
        setGroupsObjects(sortGroupsByPower(updatedGroups));
    };
    const setStaffer = async (groupId, newStafferStatus) => {
        await ApiInterface.elaborateData(await ApiInterface.setStaffer(groupId, newStafferStatus), addInfo);
        const updatedGroups = groupsObjects.map(group =>
            group.id === groupId ? {...group, staffer: newStafferStatus} : group
        );
        setGroupsObjects(sortGroupsByPower(updatedGroups));
    };

    const setPower = async (groupId, newPower) => {
        await ApiInterface.elaborateData(await ApiInterface.setPower(groupId, newPower), addInfo);
        const updatedGroups = groupsObjects.map(group =>
            group.id === groupId ? {...group, groupPower: newPower} : group
        );
        setGroupsObjects(sortGroupsByPower(updatedGroups));
    };

    const deleteGroup = async (groupId) => {
        await ApiInterface.elaborateData(await ApiInterface.deleteGroup(groupId), addInfo);
        getGroups();
    };

    const toggleEditingState = (groupId, field, state) => {
        document.getElementById(`${field}-${groupId}`).style.display = state ? "none" : "block";
        document.getElementById(`${field}-edit-${groupId}`).style.display = state ? "block" : "none";
        document.getElementById(`${field}-save-${groupId}`).style.display = state ? "inline-block" : "none";
    };

    const hasPermission = (group, permission) => {
        return group.permissions.some(p => p.permission === "*" || p.permission === permission);
    };

    async function loadPermissions() {
        let result = await ApiInterface.getPermissions();
        let json = await ApiInterface.elaborateJsonData(result, addInfo);

        if (json) {
            const staticPerms = json
                .filter(perm => !perm.includes("{"))
                .sort((a, b) => a.localeCompare(b));
            const dynamicPerms = json
                .filter(perm => perm.includes("{"))
                .sort((a, b) => a.localeCompare(b));

            setStaticPermissions(staticPerms || []);
            setDynamicPermissions(dynamicPerms || []);
        }
    }

    async function getGroups() {
        await loadPermissions();
        const result = await ApiInterface.getGroups();
        if (result.status === 418) {
            const json = await result.json();
            if (json != null && json !== "") {
                addInfo(json["object"]["type"], json["object"]["title"], json["object"]["text"], 7);
            }
        } else if (result.status === 200) {
            const groupsData = await result.json();
            const mappedGroups = groupsData.map(
                group => new GroupObject(group.id, group.groupPower, group.groupName, group.groupDescription, group.permissions, group.iconUUID, group.staffer)
            );
            setGroupsObjects(sortGroupsByPower(mappedGroups));
            setTotalGroups(mappedGroups.length);
        }
    }

    const addDynamicPermission = async (groupId, permission, value) => {
        if (value.isEmpty || value === "") {
            alert("Inserisci un valore!");
            return;
        }
        await ApiInterface.elaborateData(await ApiInterface.setPermission(groupId, permission + value, true), addInfo);
        const updatedGroups = groupsObjects.map(group => {
            if (group.id === groupId) {
                return {
                    ...group,
                    permissions: [...group.permissions, {permission: `${permission}${value}`, value: true}]
                };
            }
            return group;
        });
        setGroupsObjects(updatedGroups);
    };

    const removeDynamicPermission = async (groupId, permission) => {
        await ApiInterface.elaborateData(await ApiInterface.setPermission(groupId, permission, false), addInfo);
        const updatedGroups = groupsObjects.map(group => {
            if (group.id === groupId) {
                const updatedPermissions = group.permissions.filter(p => p.permission !== permission);
                return {...group, permissions: updatedPermissions};
            }
            return group;
        });
        setGroupsObjects(updatedGroups);
    };

    const setStaticPermission = async (groupId, permission, value) => {
        await ApiInterface.elaborateData(await ApiInterface.setPermission(groupId, permission, value), addInfo);
        const updatedGroups = groupsObjects.map(group => {
            if (group.id === groupId) {
                let updatedPermissions = [...group.permissions];
                const permIndex = updatedPermissions.findIndex(p => p.permission === permission);

                if (value && permIndex === -1) {
                    updatedPermissions.push({permission, value: true});
                } else if (!value && permIndex !== -1) {
                    updatedPermissions.splice(permIndex, 1);
                }

                return {...group, permissions: updatedPermissions};
            }
            return group;
        });

        setGroupsObjects(updatedGroups);
    };

    const createGroup = async () => {
        if (!newGroupData.name || !newGroupData.description || newGroupData.power < 0) {
            alert("Devi compilare tutti i campi!");
            return;
        }
        await ApiInterface.elaborateJsonData(await ApiInterface.createGroup(newGroupData.name, newGroupData.description, newGroupData.power, newGroupData.staffer), addInfo);
        setIsCreatingGroup(false);
        setNewGroupData({name: "", description: "", power: 0});
        getGroups();
    };

    useEffect(() => {
        getGroups();
    }, []);

    function requestNewIcon(groupId) {
        // Creazione dinamica di un input file
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*'; // Solo immagini
        fileInput.style.display = 'none';

        // Aggiunta di un listener per catturare il file selezionato
        fileInput.addEventListener('change', async (event) => {
            const file = event.target.files[0];
            if (file) {
                // Chiamata al metodo loadImage, passando il file selezionato
                await uploadNewImage(groupId, file);
            }
        });

        // Simula un click per aprire la finestra di selezione dei file
        document.body.appendChild(fileInput);
        fileInput.click();

        // Rimuove l'input file una volta usato
        fileInput.remove();
    }

    async function uploadNewImage(groupId, file) {
        const imageUID = await ApiInterface.uploadFile(file, "website/groups/icon/" + groupId, addInfo, true, true);
        if (imageUID == null) return;

        await ApiInterface.elaborateData(await ApiInterface.setIcon(groupId, imageUID), addInfo);

        let iconUrlResponse = await ApiInterface.getFileUrl(imageUID);

        if (iconUrlResponse == null) return;

        let groupIconElement  = document.getElementById(`role-img-`+groupId);
        groupIconElement.src = iconUrlResponse;
    }

    async function loadRoleImage(groupId){
        if (loadedImages.has(groupId)) return;
        loadedImages.add(groupId);

        let imageResponse = await ApiInterface.loadRoleImage(groupId);

        let groupIconElement  = document.getElementById(`role-img-`+groupId);
        groupIconElement.src = imageResponse;
    }

    return (
        <>
            <Background/>
            <div className="groupsManager__body">
                <Header marginTop={2} marginBottom={2}/>
                <div className="groupsManager__content">
                    <div className="groupsManager__main">
                        <h1>Gestione gruppi</h1>
                        <p>Qui puoi gestire i gruppi del sito</p>
                        <p>Totale gruppi: {getTotalGroups || <span className="loadingCircleTiny"/>}</p>
                        <div className="create-group-section">
                            {!isCreatingGroup && (
                                <button className="create-group-btn" onClick={() => setIsCreatingGroup(true)}>
                                    Crea Gruppo
                                </button>
                            )}
                            {isCreatingGroup && (
                                <div className="create-group-form">
                                    <input
                                        type="text"
                                        placeholder="Nome Gruppo"
                                        value={newGroupData.name}
                                        onChange={(e) => setNewGroupData({...newGroupData, name: e.target.value})}
                                    />
                                    <textarea
                                        placeholder="Descrizione"
                                        value={newGroupData.description}
                                        onChange={(e) => setNewGroupData({
                                            ...newGroupData,
                                            description: e.target.value
                                        })}
                                    />
                                    <input
                                        type="number"
                                        placeholder="Power"
                                        value={newGroupData.power}
                                        onChange={(e) => setNewGroupData({
                                            ...newGroupData,
                                            power: parseInt(e.target.value, 10)
                                        })}
                                    />
                                    <select
                                        value={newGroupData.staffer}
                                        onChange={(e) => setNewGroupData({
                                            ...newGroupData,
                                            staffer: e.target.value
                                        })}
                                    >
                                        <option value={0} label={"Ruolo Utente"} selected/>
                                        <option value={1} label={"Ruolo Staffer"}/>
                                    </select>
                                    <div className="button-group">
                                        <button className="save-group-btn" onClick={createGroup}>Salva</button>
                                        <button className="cancel-group-btn"
                                                onClick={() => setIsCreatingGroup(false)}>Annulla
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="groups-table-wrapper">
                            <button onClick={(e) => {
                                Redirects.send("/", e)
                            }} className={"back-button"}>⬅ Indietro
                            </button><br/>
                            <h1>Gruppi Staff</h1>
                            <table className="groupsTable">
                                <thead>
                                <tr>
                                    <th>Permessi \ Gruppi</th>
                                    {groupsObjects
                                        .filter((group) => group.staffer)
                                        .map((group) => (
                                            <th key={group.id}>
                                                {group.groupName} [{group.id}]<br/><br/>
                                                <select
                                                    value={group.staffer ? "true" : "false"}
                                                    onChange={(e) =>
                                                        setStaffer(group.id, e.target.value === "true")
                                                    }
                                                >
                                                    <option value="false" label={"Utente"}/>
                                                    <option value="true" label={"Staffer"}/>
                                                </select>
                                                <span
                                                    className="group-description"
                                                    id={`description-${group.id}`}
                                                    onClick={() => toggleEditingState(group.id, "description", true)}
                                                >
                                                    {group.groupDescription}
                                            </span>
                                                <textarea
                                                    id={`description-edit-${group.id}`}
                                                    className="group-description-edit"
                                                    defaultValue={group.groupDescription}
                                                    style={{display: "none"}}
                                                />
                                                <button
                                                    id={`description-save-${group.id}`}
                                                    className="save-description-btn"
                                                    style={{display: "none"}}
                                                    onClick={() => {
                                                        const value = document.getElementById(`description-edit-${group.id}`).value;
                                                        saveDescription(group.id, value);
                                                    }}
                                                >💾
                                                </button>
                                                <br/><br/>
                                                <span
                                                    className={`group-power ${group.groupName === "WEB MASTER" ? "readonly" : ""}`}
                                                    id={`power-${group.id}`}
                                                    onClick={() => toggleEditingState(group.id, "power", true)}
                                                >
                                                    Power: {group.groupPower}
                                                </span>
                                                <input
                                                    type="number"
                                                    id={`power-edit-${group.id}`}
                                                    className="group-power-edit"
                                                    style={{display: "none"}}
                                                    defaultValue={group.groupPower}
                                                />
                                                <button
                                                    id={`power-save-${group.id}`}
                                                    className="save-power-btn"
                                                    style={{display: "none"}}
                                                    onClick={() => {
                                                        const value = document.getElementById(`power-edit-${group.id}`).value;
                                                        savePower(group.id, value);
                                                    }}
                                                >💾
                                                </button>
                                                <br/>
                                                <button
                                                    className="delete-btn"
                                                    onClick={() => {
                                                        deleteGroup(group.id)
                                                    }}>
                                                    🗑
                                                </button>
                                                <br/>
                                                <img id={`role-img-${group.id}`}
                                                     className="role-img"
                                                     src={"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"}
                                                     alt={`role-img-${group.groupName}`}
                                                     onClick={() => {
                                                         requestNewIcon(group.id);
                                                     }}
                                                     onLoad={() => loadRoleImage(group.id)}
                                                />
                                            </th>
                                        ))}
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td colSpan={groupsObjects.length + 1}
                                        className="permission-section-header">Permessi Statici
                                    </td>
                                </tr>
                                {staticPermissions.map((perm) => (
                                    <tr key={perm}>
                                        <td className="permission-name">{perm}</td>
                                        {groupsObjects
                                            .filter((group) => group.staffer)
                                            .map((group) => (
                                                <td key={`${group.id}-${perm}`}>
                                                    <input
                                                        type="checkbox"
                                                        checked={hasPermission(group, perm)}
                                                        onChange={(e) => setStaticPermission(group.id, perm, e.target.checked)}
                                                    />
                                                </td>
                                            ))}
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={groupsObjects.length + 1}
                                        className="permission-section-header">Permessi Dinamici
                                    </td>
                                </tr>
                                {dynamicPermissions.map((dynamicPerm) => {
                                    const basePerm = dynamicPerm.replace("{id}", "");
                                    return (
                                        <tr key={dynamicPerm}>
                                            <td className="permission-name">{basePerm}</td>
                                            {groupsObjects
                                                .filter((group) => group.staffer)
                                                .map((group) => {
                                                    const hasWildcardPermission = group.permissions.some((p) => p.permission === "*" || p.permission === (basePerm + "*"));

                                                    return (
                                                        <td key={`${group.id}-${dynamicPerm}`}>
                                                            {group.permissions
                                                                .filter((p) => p.permission.startsWith(basePerm) || p.permission === "*")
                                                                .map((permission) => (
                                                                    <div key={permission.permission}
                                                                         className="dynamic-permission">
                                                                        <span className="dynamic-permission-text">
                                                                            {permission.permission === "*" || permission.permission === (basePerm + "*") ? (
                                                                                <strong>*</strong>
                                                                            ) : (
                                                                                permission.permission.split(".").pop()
                                                                            )}
                                                                        </span>
                                                                        <button
                                                                            className="btn btn-remove"
                                                                            onClick={() => removeDynamicPermission(group.id, permission.permission)}
                                                                        >
                                                                            X
                                                                        </button>
                                                                    </div>
                                                                ))}

                                                            {!hasWildcardPermission && (
                                                                <div className="add-dynamic-permission">
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Value"
                                                                        id={`new-${group.id}-${basePerm}`}
                                                                        className="input text-input"
                                                                    />
                                                                    <button
                                                                        className="btn btn-add"
                                                                        onClick={() => {
                                                                            const newPermValue = document.getElementById(`new-${group.id}-${basePerm}`).value;
                                                                            addDynamicPermission(group.id, basePerm, newPermValue);
                                                                        }}
                                                                    >
                                                                        Aggiungi
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                        <br/>
                        <div className="groups-table-wrapper">
                            <h1>Gruppi Utenti</h1>
                            <table className="groupsTable">
                                <thead>
                                <tr>
                                    <th>Gruppi</th>
                                    {groupsObjects
                                        .filter((group) => !group.staffer)
                                        .map((group) => (
                                            <th key={group.id}>
                                                {group.groupName} [{group.id}]<br/><br/>
                                                <select
                                                    value={group.staffer ? "true" : "false"}
                                                    onChange={(e) =>
                                                        setStaffer(group.id, e.target.value === "true")
                                                    }
                                                >
                                                    <option value="false" label={"Utente"}/>
                                                    <option value="true" label={"Staffer"}/>
                                                </select>
                                                <span
                                                    className="group-description"
                                                    id={`description-${group.id}`}
                                                    onClick={() => toggleEditingState(group.id, "description", true)}
                                                >
                                                    {group.groupDescription}
                                            </span>
                                                <textarea
                                                    id={`description-edit-${group.id}`}
                                                    className="group-description-edit"
                                                    defaultValue={group.groupDescription}
                                                    style={{display: "none"}}
                                                />
                                                <button
                                                    id={`description-save-${group.id}`}
                                                    className="save-description-btn"
                                                    style={{display: "none"}}
                                                    onClick={() => {
                                                        const value = document.getElementById(`description-edit-${group.id}`).value;
                                                        saveDescription(group.id, value);
                                                    }}
                                                >💾
                                                </button>
                                                <br/>
                                                <button
                                                    className="delete-btn"
                                                    onClick={() => {
                                                        deleteGroup(group.id)
                                                    }}>
                                                    🗑
                                                </button>
                                                <br/>
                                                <img id={`role-img-${group.id}`}
                                                     className="role-img"
                                                     src={"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"}
                                                     alt={`role-img-${group.groupName}`}
                                                     onClick={() => {
                                                         requestNewIcon(group.id);
                                                     }}
                                                     onLoad={() => loadRoleImage(group.id)}
                                                />
                                            </th>
                                        ))}
                                </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
};

export default GroupsManager;