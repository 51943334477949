import React, { useRef, useEffect, useState, useCallback } from "react";
import styles from "./Editor.module.css";

const ICONS = {
    bold: "https://img.icons8.com/material-outlined/24/ffffff/bold.png",
    italic: "https://img.icons8.com/material-outlined/24/ffffff/italic.png",
    underline: "https://img.icons8.com/material-outlined/24/ffffff/underline.png",
    alignLeft: "https://img.icons8.com/material-outlined/24/ffffff/align-left.png",
    alignCenter: "https://img.icons8.com/material-outlined/24/ffffff/align-center.png",
    alignRight: "https://img.icons8.com/material-outlined/24/ffffff/align-right.png",
    fontSize: "https://img.icons8.com/material-outlined/24/ffffff/text-size.png",
    link: "https://img.icons8.com/material-outlined/24/ffffff/link.png",
    image: "https://img.icons8.com/material-outlined/24/ffffff/image.png",
    section: "https://img.icons8.com/material-outlined/24/ffffff/view-headline.png",
    textColor: "https://img.icons8.com/material-outlined/24/ffffff/paint-palette.png",
    clearFormat: "https://img.icons8.com/material-outlined/24/ffffff/erase.png",
};

interface EditorProps {
    value: string;
    onChange: (html: string) => void;
}

const allowedFontSizes = ["8", "10", "12", "14", "16", "18", "20", "22", "24", "26", "28", "30", "32"];

export default function Editor({ value, onChange }: EditorProps) {
    const editorRef = useRef<HTMLDivElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const fontSizeSelectRef = useRef<HTMLSelectElement>(null);

    const [currentColor, setCurrentColor] = useState<string>("#000000");
    const [selection, setSelection] = useState<Range | null>(null);
    const [showLinkInput, setShowLinkInput] = useState<boolean>(false);
    const [linkUrl, setLinkUrl] = useState<string>("");

    const [selectedImage, setSelectedImage] = useState<HTMLImageElement | null>(null);
    const [sectionMenu, setSectionMenu] = useState<{ sectionEl: HTMLElement; pos: { top: number; left: number } } | null>(null);

    const [activeFormats, setActiveFormats] = useState({
        bold: false,
        italic: false,
        underline: false,
        justifyLeft: false,
        justifyCenter: false,
        justifyRight: false,
    });

    // Funzione di utilità: controlla se un range è dentro l'editor
    function selectionIsInsideEditor(range: Range) {
        if (!editorRef.current) return false;
        return editorRef.current.contains(range.commonAncestorContainer);
    }

    useEffect(() => {
        // Allinea l'HTML interno con prop value se l'editor non è attivo
        if (editorRef.current && document.activeElement !== editorRef.current) {
            editorRef.current.innerHTML = value;
        }
    }, [value]);

    useEffect(() => {
        // aggiorna lo stato (bold, italic, underline, ecc.) e la dimensione del font
        const updateFormatsAndFontSize = () => {
            const sel = window.getSelection();
            if (!sel || sel.rangeCount === 0) {
                // Se non c'è selezione, resettiamo i formati
                setActiveFormats({
                    bold: false,
                    italic: false,
                    underline: false,
                    justifyLeft: false,
                    justifyCenter: false,
                    justifyRight: false,
                });
                // e, se vuoi, resettiamo anche il fontSize a 16
                if (fontSizeSelectRef.current) {
                    fontSizeSelectRef.current.value = "16";
                }
                return;
            }

            const range = sel.getRangeAt(0);
            // Se la selezione è fuori dall'editor, resettiamo la toolbar
            if (!selectionIsInsideEditor(range)) {
                setActiveFormats({
                    bold: false,
                    italic: false,
                    underline: false,
                    justifyLeft: false,
                    justifyCenter: false,
                    justifyRight: false,
                });
                if (fontSizeSelectRef.current) {
                    fontSizeSelectRef.current.value = "16";
                }
                return;
            }

            // Se la selezione è dentro l'editor
            setActiveFormats({
                bold: document.queryCommandState("bold"),
                italic: document.queryCommandState("italic"),
                underline: document.queryCommandState("underline"),
                justifyLeft: document.queryCommandState("justifyLeft"),
                justifyCenter: document.queryCommandState("justifyCenter"),
                justifyRight: document.queryCommandState("justifyRight"),
            });

            if (fontSizeSelectRef.current) {
                const parent = range.startContainer.parentElement;
                if (parent) {
                    const fs = window.getComputedStyle(parent).fontSize.replace("px", "");
                    if (allowedFontSizes.includes(fs)) {
                        fontSizeSelectRef.current.value = fs;
                    } else {
                        let customOption = fontSizeSelectRef.current.querySelector("option.customOption") as HTMLOptionElement;
                        if (!customOption) {
                            customOption = document.createElement("option");
                            customOption.className = "customOption";
                            fontSizeSelectRef.current.appendChild(customOption);
                        }
                        customOption.value = fs;
                        customOption.textContent = fs + "px";
                        fontSizeSelectRef.current.value = fs;
                    }
                }
            }
        };

        document.addEventListener("selectionchange", updateFormatsAndFontSize);
        return () => document.removeEventListener("selectionchange", updateFormatsAndFontSize);
    }, []);

    // Chiudi menù immagine e sezione se clicchiamo altrove
    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            const target = e.target as HTMLElement;
            const imageMenuEl = document.getElementById("image-menu");

            if (selectedImage && imageMenuEl) {
                if (!selectedImage.contains(target) && !imageMenuEl.contains(target)) {
                    setSelectedImage(null);
                }
            }
            if (sectionMenu) {
                if (!sectionMenu.sectionEl.contains(target)) {
                    setSectionMenu(null);
                }
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [selectedImage, sectionMenu]);

    // Salva nel nostro stato solo se la selezione è interna all'editor
    const saveSelection = () => {
        const sel = window.getSelection();
        if (sel && sel.rangeCount > 0) {
            const range = sel.getRangeAt(0);
            if (selectionIsInsideEditor(range)) {
                setSelection(range);
            } else {
                setSelection(null);
            }
        }
    };

    // Ripristina la selezione salvata (se esiste)
    const restoreSelection = () => {
        if (!selection) return;
        const sel = window.getSelection();
        if (!sel) return;
        sel.removeAllRanges();
        sel.addRange(selection);
    };

    // Aggiorna onChange
    const handleInput = useCallback(() => {
        if (editorRef.current) {
            // Rimuove eventuali inline background-color
            editorRef.current.querySelectorAll("[style]").forEach(el => {
                if ((el as HTMLElement).style.backgroundColor) {
                    (el as HTMLElement).style.removeProperty("background-color");
                }
            });
            onChange(editorRef.current.innerHTML);
        }
    }, [onChange]);

    const focusEditor = () => {
        editorRef.current?.focus();
    };

    // Esegue un comando di formattazione se la selezione è nell'editor
    const formatCommand = (command: string, value?: string) => {
        if (!selection) return; // se la selezione è nulla o fuori, ignora
        document.execCommand(command, false, value);
        handleInput();
        focusEditor();
    };

    const clearFormatting = () => {
        restoreSelection();
        if (!selection) return;
        document.execCommand("removeFormat");
        document.execCommand("unlink");
        handleInput();
        focusEditor();
    };

    const applyFontSize = (size: number) => {
        const sel = window.getSelection();
        if (!sel || sel.rangeCount === 0) return;
        const range = sel.getRangeAt(0);

        if (!selectionIsInsideEditor(range)) return;

        if (range.collapsed) {
            const span = document.createElement("span");
            span.style.fontSize = `${size}px`;
            const emptyText = document.createTextNode("");
            span.appendChild(emptyText);
            range.insertNode(span);

            const newRange = document.createRange();
            newRange.setStart(emptyText, 0);
            newRange.setEnd(emptyText, 0);
            sel.removeAllRanges();
            sel.addRange(newRange);

            handleInput();
            focusEditor();
            return;
        }

        const parent = range.startContainer.parentElement;
        if (parent && parent.tagName === "SPAN" && parent.style.fontSize === `${size}px`) {
            const fragment = document.createDocumentFragment();
            while (parent.firstChild) {
                fragment.appendChild(parent.firstChild);
            }
            parent.parentNode?.replaceChild(fragment, parent);
            handleInput();
            focusEditor();
            return;
        }

        const span = document.createElement("span");
        span.style.fontSize = `${size}px`;
        span.appendChild(range.extractContents());
        range.insertNode(span);

        handleInput();
        focusEditor();
    };

    const handleFontSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const val = e.target.value;
        if (val === "custom") {
            const custom = prompt("Inserisci una dimensione custom (max 128):", "16");
            if (custom) {
                const size = parseInt(custom, 10);
                if (!isNaN(size)) {
                    applyFontSize(Math.min(size, 128));
                }
            }
        } else {
            const size = parseInt(val, 10);
            applyFontSize(size);
        }
    };



    // Gestione immagini
    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file && /image\/(png|jpeg)/.test(file.type)) {
            const reader = new FileReader();
            reader.onload = (ev) => {
                const dataURL = ev.target?.result;
                if (dataURL) {
                    restoreSelection();
                    if (!selection) return;
                    document.execCommand("insertImage", false, dataURL as string);
                    handleInput();
                    focusEditor();
                }
            };
            reader.readAsDataURL(file);
        }
        if (fileInputRef.current) fileInputRef.current.value = "";
    };

    const updateImageWidth = (img: HTMLImageElement, newWidth: string) => {
        let newId = img.id;
        if (!newId) {
            newId = "img_" + Date.now() + "_" + Math.floor(Math.random() * 1000);
        }
        const newHTML = `<img src="${img.src}" alt="${img.alt || ''}" id="${newId}" style="width: ${newWidth};" />`;
        const range = document.createRange();
        range.selectNode(img);
        const sel = window.getSelection();
        if (sel) {
            sel.removeAllRanges();
            sel.addRange(range);
        }
        document.execCommand("delete");
        document.execCommand("insertHTML", false, newHTML);
        const newImg = document.getElementById(newId) as HTMLImageElement;
        if (newImg) {
            setSelectedImage(newImg);
        }
        handleInput();
    };

    const adjustImageWidth = (img: HTMLImageElement, delta: number | string) => {
        if (typeof delta === "string") {
            updateImageWidth(img, delta);
        } else {
            const currentWidth = parseInt(getComputedStyle(img).width, 10);
            let newWidth = currentWidth + delta;
            if (newWidth < 50) newWidth = 50;
            updateImageWidth(img, newWidth + "px");
        }
    };

    const deleteSelectedImage = () => {
        if (selectedImage) {
            const range = document.createRange();
            range.selectNode(selectedImage);
            const sel = window.getSelection();
            if (sel) {
                sel.removeAllRanges();
                sel.addRange(range);
            }
            document.execCommand("delete");
            handleInput();
            setSelectedImage(null);
        }
    };

    // Gestione sezioni
    const insertSection = () => {
        restoreSelection();
        if (!selection) return;

        const sel = window.getSelection();
        if (!sel) return;

        let content = "";
        if (!sel.isCollapsed) {
            const range = sel.getRangeAt(0);
            if (!selectionIsInsideEditor(range)) return;

            const container = document.createElement("div");
            container.appendChild(range.extractContents());
            content = container.innerHTML;
        }
        const sectionHTML = `<section style="width:100%;height:fit-content;display:block;overflow:auto;">${content}</section>`;

        if (sel.anchorNode) {
            const currentSection = (sel.anchorNode as HTMLElement).parentElement?.closest(`.${styles.sectionDivider}`);
            if (currentSection) {
                currentSection.insertAdjacentHTML("afterend", sectionHTML);
            } else {
                document.execCommand("insertHTML", false, sectionHTML);
            }
        }
        handleInput();
        focusEditor();
    };

    const openSectionMenu = (sectionEl: HTMLElement) => {
        const rect = sectionEl.getBoundingClientRect();
        setSectionMenu({ sectionEl, pos: { top: rect.top - 40, left: rect.left } });
    };

    const removeSection = (sectionEl: HTMLElement) => {
        const fragment = document.createDocumentFragment();
        while (sectionEl.firstChild) {
            fragment.appendChild(sectionEl.firstChild);
        }
        sectionEl.parentNode?.replaceChild(fragment, sectionEl);
        handleInput();
        setSectionMenu(null);
        focusEditor();
    };

    const handleEditorClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as HTMLElement;
        if (target.closest(`.${styles.imageMenu}`)) return;
        if (target.tagName === "IMG") {
            if (selectedImage !== target) {
                setSelectedImage(target as HTMLImageElement);
                return;
            }
        }
        const sectionEl = target.closest(`.${styles.sectionDivider}`) as HTMLElement;
        if (sectionEl) {
            e.stopPropagation();
            openSectionMenu(sectionEl);
            return;
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        document.querySelectorAll(".willDelete").forEach(el => el.classList.remove("willDelete"));
        const sel = window.getSelection();
        if (!sel || sel.rangeCount === 0) return;
        const range = sel.getRangeAt(0);

        if (e.key === "Delete") {
            const sectionEl = range.startContainer.parentElement?.closest(`.${styles.sectionDivider}`);
            if (sectionEl && range.collapsed) {
                const tempRange = document.createRange();
                tempRange.selectNodeContents(sectionEl);
                tempRange.setStart(range.endContainer, range.endOffset);
                if (tempRange.toString().trim() === "") {
                    e.preventDefault();
                    sectionEl.classList.add("willDelete");
                    return;
                }
            }
        } else if (e.key === "Backspace") {
            if (range.startOffset === 0) {
                const currentSection = range.startContainer.parentElement?.closest(`.${styles.sectionDivider}`);
                if (currentSection) {
                    const prevSection = currentSection.previousElementSibling;
                    if (prevSection && prevSection.classList.contains(styles.sectionDivider)) {
                        prevSection.classList.add("willDelete");
                    }
                }
            }
        } else if (e.key === "Enter" && e.shiftKey) {
            e.preventDefault(); // Previeni il comportamento di default del browser

            // Recupera la selezione corrente
            const sel = window.getSelection();
            if (sel && sel.rangeCount > 0) {
                const range = sel.getRangeAt(0);

                // Crea un elemento <br> e lo inserisce nella posizione del cursore
                const br = document.createElement("br");
                range.insertNode(br);

                // Crea un nodo di testo invisibile per posizionare correttamente il cursore
                const zeroWidthText = document.createTextNode("\u200B");

                // Inserisce il nodo di testo DOPO il <br>
                range.setStartAfter(br);             // Sposta il range dopo il <br>
                range.insertNode(zeroWidthText);    // Inserisce il nodo di testo dopo il <br>

                // Aggiorna il range per posizionare il cursore dopo il nodo zero-width
                const newRange = document.createRange();
                newRange.setStartAfter(zeroWidthText);
                newRange.collapse(true);

                // Rimuovi tutte le selezioni e applica il nuovo range
                sel.removeAllRanges();
                sel.addRange(newRange);
            }
        }
    };

    const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
        document.querySelectorAll(".willDelete").forEach(el => el.classList.remove("willDelete"));
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0];
            if (/image\/(png|jpeg)/.test(file.type)) {
                const reader = new FileReader();
                reader.onload = (ev) => {
                    const dataURL = ev.target?.result;
                    if (dataURL) {
                        restoreSelection();
                        if (!selection) return;
                        document.execCommand("insertImage", false, dataURL as string);
                        handleInput();
                        focusEditor();
                    }
                };
                reader.readAsDataURL(file);
            }
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
        const items = e.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.type.indexOf("image") !== -1) {
                e.preventDefault();
                const file = item.getAsFile();
                if (file) {
                    const reader = new FileReader();
                    reader.onload = (ev) => {
                        const dataURL = ev.target?.result;
                        if (dataURL) {
                            restoreSelection();
                            if (!selection) return;
                            document.execCommand("insertImage", false, dataURL as string);
                            handleInput();
                            focusEditor();
                        }
                    };
                    reader.readAsDataURL(file);
                }
            }else if (item.type.indexOf("text/plain") !== -1) {
                e.preventDefault();
                // Estrae il contenuto incollato come testo
                item.getAsString(async (text) => {
                    // Controlla se è un log di Minecraft o troppo lungo
                    if (isMinecraftLogOrTooLong(text)) {
                        try {
                            // Carica il testo su mclo.gs e ottieni il link
                            const logLink = await uploadLogToMclogs(text);

                            // Sostituisci il contenuto incollato con il link
                            insertTextAtCursor(logLink);
                            handleInput();
                            focusEditor();
                        } catch (error) {
                        }
                    }else{
                        insertTextAtCursor(text);
                        handleInput();
                        focusEditor();
                    }
                });

            }
        }
    };

    function isMinecraftLogOrTooLong(text: string): boolean {
        const maxLength = 1000; // Limite dei caratteri oltre il quale consideriamo il file "troppo lungo"
        const minecraftIndicators = ["[Server thread]", "[INFO]", "[ERROR]", "[WARN]"];

        // Controlla lunghezza del testo
        if (text.length > maxLength) {
            return true; // Testo troppo lungo
        }

        // Controlla se il testo contiene parole chiave tipiche di un log di Minecraft
        for (const keyword of minecraftIndicators) {
            if (text.includes(keyword)) {
                return true; // È un log di Minecraft
            }
        }

        return false; // Non è né troppo lungo né un log di Minecraft
    }

    async function uploadLogToMclogs(log: string): Promise<string> {
        try {
            const response = await fetch("https://api.mclo.gs/1/log", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    "content": log,
                })
            });

            if (!response.ok) {
                throw new Error("Impossibile caricare il log su mclo.gs");
            }

            const data = await response.json();

            // Ritorna il link al log caricato
            return data.url || "";
        } catch (error) {
            window.addInfo("alert", "Errore nell'incollare il testo",
                "Quello che hai provato a incollare sembra un log di Minecraft o un testo molto lungo.<br>" +
                "Abbiamo provato a incollarlo per te ma c'è stato un errore durante il caricamento del log su mclo.gs<br>" +
                "Prova a incollare il testo direttamente sul sito di mclo.gs e copia il link qui.", 15);
            return "";
        }
    }

    function insertTextAtCursor(text: string): void {
        const sel = window.getSelection(); // Ottiene la selezione corrente
        if (!sel || sel.rangeCount === 0) return;

        const range = sel.getRangeAt(0); // Ottiene il primo range
        range.deleteContents();         // Rimuove il contenuto selezionato
        range.insertNode(document.createTextNode(text)); // Inserisce il testo
    }


    const insertLink = () => {
        restoreSelection();
        if (!selection) return;

        if (linkUrl) {
            document.execCommand("createLink", false, linkUrl);
            const anchors = editorRef.current?.getElementsByTagName("a");
            if (anchors) {
                Array.from(anchors).forEach(anchor => anchor.classList.add(styles.customLink));
            }
            handleInput();
            setLinkUrl("");
            setShowLinkInput(false);
            focusEditor();
        }
    };

    return (
        <div className={styles.editorWrapper}>
            <div className={styles.toolbar}>
                <div className={styles.toolbarGroup}>
                    <button
                        id="bold"
                        className={`${styles.toolbarButton} ${activeFormats.bold ? styles.active : ""}`}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => formatCommand("bold")}
                    >
                        <img src={ICONS.bold} alt="Bold" />
                    </button>
                    <button
                        id="italic"
                        className={`${styles.toolbarButton} ${activeFormats.italic ? styles.active : ""}`}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => formatCommand("italic")}
                    >
                        <img src={ICONS.italic} alt="Italic" />
                    </button>
                    <button
                        id="underline"
                        className={`${styles.toolbarButton} ${activeFormats.underline ? styles.active : ""}`}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => formatCommand("underline")}
                    >
                        <img src={ICONS.underline} alt="Underline" />
                    </button>
                    <button
                        id="clearFormat"
                        className={styles.toolbarButton}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={clearFormatting}
                    >
                        <img src={ICONS.clearFormat} alt="Clear" />
                    </button>
                </div>

                <div className={styles.toolbarGroup}>
                    <select
                        ref={fontSizeSelectRef}
                        className={styles.fontSizeDropdown}
                        defaultValue="16"
                        onChange={handleFontSizeChange}
                    >
                        {["8", "10", "12", "14", "16", "18", "20", "22", "24", "26", "28", "30", "32"].map(size => (
                            <option key={size} value={size}>{size}px</option>
                        ))}
                        {/* L'opzione custom sarà aggiunta dinamicamente se non in lista */}
                    </select>

                    <button
                        id="textColor"
                        className={styles.toolbarButton}
                        onMouseDown={(e) => e.preventDefault()}
                    >
                        <img src={ICONS.textColor} alt="Text Color" />
                        <span className={styles.colorIndicator} style={{ backgroundColor: currentColor }} />
                        <input
                            type="color"
                            value={currentColor}
                            onChange={(e) => {
                                setCurrentColor(e.target.value);
                                formatCommand("foreColor", e.target.value);
                            }}
                            style={{
                                position: "absolute",
                                opacity: 0,
                                width: "100%",
                                height: "100%",
                                left: 0,
                                top: 0,
                                cursor: "pointer"
                            }}
                        />
                    </button>
                </div>

                <div className={styles.toolbarGroup}>
                    <button
                        id="alignLeft"
                        className={`${styles.toolbarButton} ${activeFormats.justifyLeft ? styles.active : ""}`}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => formatCommand("justifyLeft")}
                    >
                        <img src={ICONS.alignLeft} alt="Align Left" />
                    </button>
                    <button
                        id="alignCenter"
                        className={`${styles.toolbarButton} ${activeFormats.justifyCenter ? styles.active : ""}`}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => formatCommand("justifyCenter")}
                    >
                        <img src={ICONS.alignCenter} alt="Align Center" />
                    </button>
                    <button
                        id="alignRight"
                        className={`${styles.toolbarButton} ${activeFormats.justifyRight ? styles.active : ""}`}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => formatCommand("justifyRight")}
                    >
                        <img src={ICONS.alignRight} alt="Align Right" />
                    </button>
                </div>

                <div className={styles.toolbarGroup}>
                    <button
                        id="section"
                        className={styles.toolbarButton}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={insertSection}
                    >
                        <img src={ICONS.section} alt="Section" />
                    </button>
                </div>

                <div className={styles.toolbarGroup}>
                    <button
                        id="link"
                        className={styles.toolbarButton}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => setShowLinkInput(true)}
                    >
                        <img src={ICONS.link} alt="Link" />
                    </button>
                    <button
                        id="image"
                        className={styles.toolbarButton}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => fileInputRef.current?.click()}
                    >
                        <img src={ICONS.image} alt="Image" />
                    </button>
                    <input
                        type="file"
                        accept="image/png, image/jpeg"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                    />
                </div>
            </div>

            <div
                className={styles.editorContainer}
                onMouseUp={saveSelection}
                onKeyUp={(e) => { saveSelection(); handleKeyUp(e); }}
                onKeyDown={handleKeyDown}
                onDrop={handleDrop}
                onPaste={handlePaste}
                onClick={handleEditorClick}
            >
                <div
                    ref={editorRef}
                    className={styles.editorContent}
                    contentEditable
                    onInput={handleInput}
                />
            </div>

            {showLinkInput && (
                <div className={styles.linkModal}>
                    <div className={styles.linkModalContent}>
                        <h3>Inserisci Link</h3>
                        <input
                            type="text"
                            value={linkUrl}
                            onChange={(e) => setLinkUrl(e.target.value)}
                            placeholder="https://..."
                        />
                        <div className={styles.linkModalButtons}>
                            <button onClick={insertLink}>Inserisci</button>
                            <button onClick={() => setShowLinkInput(false)}>Annulla</button>
                        </div>
                    </div>
                </div>
            )}

            {selectedImage && (
                <div
                    id="image-menu"
                    className={styles.imageMenu}
                    style={{
                        top: selectedImage.getBoundingClientRect().bottom + 5,
                        left: selectedImage.getBoundingClientRect().left
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <button onClick={() => adjustImageWidth(selectedImage, "100%")}>100%</button>
                    <button onClick={() => adjustImageWidth(selectedImage, "50%")}>50%</button>
                    <button onClick={() => adjustImageWidth(selectedImage, 50)}>+50px</button>
                    <button onClick={() => adjustImageWidth(selectedImage, -50)}>-50px</button>
                    <button onClick={deleteSelectedImage}>Delete</button>
                    <button onClick={() => setSelectedImage(null)}>Close</button>
                </div>
            )}

            {sectionMenu && (
                <div
                    className={`${styles.imageMenu} ${styles.sectionMenu}`}
                    style={{ top: sectionMenu.pos.top, right: sectionMenu.pos.left }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <button
                        className={styles.deleteButton}
                        onClick={() => removeSection(sectionMenu.sectionEl)}
                    >
                        Elimina sezione
                    </button>
                    <button onClick={() => setSectionMenu(null)}>Close</button>
                </div>
            )}
        </div>
    );
}
