import './ServerPage.css';

import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";

import FileHandler from "./objects/file/FileHandler";
import ServerObj from "./objects/Main/ServerObj";
import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useInfoBox} from "@/commons/infoBox/InfoBox";
import Redirects from "@/commons/utils/Redirects";
import ApiInterface from "@/commons/api/ApiInterface";


const ServerPage = () => {
    let {currentServer} = useParams();
    const [servers, setServers] = useState<ServerObj[] | undefined>(undefined);
    const {addInfo} = useInfoBox();


    async function FileManager() {
        setServers([]);
        let files = await FileHandler.getFileList()

        for (let i = 0; i < files.length; i++) {
            let fileName = files[i]
            let fileData = await FileHandler.loadFile(fileName);
            let obj = new ServerObj(fileData.title, fileData.description, fileData.preview, fileName)

            addServer(obj)
        }
    }

    function addServer(server: ServerObj): void {
        setServers((prevServers) =>
            prevServers ? [...prevServers, server] : [server]
        );
    }

    useEffect(() => {
        FileManager();
    }, []);

    return (
        <>
            <Background/>
            <div className={"serverPage__body"}>
                <Header marginTop={2} marginBottom={5}/>
                <div className={"serverPage__modalitiesBox"}>
                    {currentServer ? (
                        (() => {
                            // Cerchiamo tra i server quello il cui `title` corrisponde a `currentServer` (ignorando maiuscole/minuscole)
                            const foundServer = servers?.find(
                                (obj) => currentServer && obj.title.toLowerCase() === currentServer.toLowerCase()
                            );
                            // Se esiste, rendiamo la vista completa del server
                            // Altrimenti mostriamo la preview di tutta la lista
                            return foundServer
                                ? <>
                                    <div className={"serverPage__backButton"}>
                                        <button
                                            onClick={(e) => Redirects.send("/server", e)}>Vedi le altre modalità
                                        </button>
                                    </div>
                                    <br/>
                                    <div className={"serverPage__fullPage"}>
                                        {foundServer.renderer()}
                                    </div>
                                </>
                                : servers?.map((obj) => obj.previewRender());
                        })()
                    ) : (
                        // Se `currentServer` non è impostato, mostriamo subito la preview di tutti i server
                        servers?.map((obj) => obj.previewRender())
                    )}
                </div>
                <Footer/>
            </div>
        </>
    );

}

export default ServerPage;